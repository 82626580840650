import { ScaleFade } from "@chakra-ui/react"
import { navigate } from "gatsby"
import React from "react"
import { Authenticated } from "../../components/auth/Authenticated"
import { Layout } from "../../components/layout/Layout"
import { LayoutBody } from "../../components/layout/LayoutBody"
import { Nav } from "../../components/layout/Nav"
import { EmailUnverified } from "../../components/auth/VerifyEmail"
import { useReloadWhenRevisitTab } from "../../useReloadWhenRevisitTab"
import { PageTitle } from "../../components/typing-test/PageTitle"
import { useMixpanel } from "gatsby-plugin-mixpanel"

export default function VerifyEmailPage() {
  const mixpanel = useMixpanel()
  // Auto-reload to refresh stale emailVerified state.
  // If user goes to email, clicks, closes that page, and comes back, want to show updated state.
  // Page doesn't poll for new state so this is how we do it.
  useReloadWhenRevisitTab()

  return (
    <>
      <PageTitle>Verify Email</PageTitle>
      <Layout>
        <Nav />
        <LayoutBody size="md">
          <Authenticated>
            <ScaleFade in>
              <EmailUnverified
                onVerified={() => {
                  mixpanel.track("Sign up - email verified")
                  navigate("/account/email-verified")
                }}
              />
            </ScaleFade>
          </Authenticated>
        </LayoutBody>
      </Layout>
    </>
  )
}
