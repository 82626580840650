import { useEffect } from "react"

/**
 * Reload page if user leaves and then comes back.
 * This is useful for when an email is sent and user confirms something via a link, but later comes back to the tab.
 */
export function useReloadWhenRevisitTab() {
  useEffect(() => {
    const handleVisibilityChange = () => {
      !document.hidden && window.location.reload()
    }

    document.addEventListener("visibilitychange", handleVisibilityChange, false)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])
}
